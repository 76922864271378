@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

.App {
  text-align: center;
  background-color: #171717;
  font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
  min-height: 100vh;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #171717;
  min-height: 5 vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.App-rect {
  stroke: #171717; 
  stroke-width: 4; 
  stroke-dasharray: none; 
  stroke-linecap: butt; 
  stroke-dashoffset: 0; 
  stroke-linejoin: miter; 
  stroke-miterlimit: 4; 
  fill-rule: nonzero; 
  opacity: 1;
}

.avaliable {
  fill: rgb(137, 255, 137); 
}
.unknown {
  fill: rgb(245, 213, 115); 
}
.busy {
  fill: rgb(255, 132, 132); 
}
.notinuse {
  fill: rgb(45, 45, 45); 
}


svg text{
  text-anchor: middle;
  dominant-baseline: middle;
}

.centre-buttons {
  display: flex;
  align-items: center;
  justify-content: center
}